
  
const initState = {
    search: '',
    category:'',
    cartCount:0,
    categoryList:[]
  };
  
  const SearchMyCatalogueReducer = (state = initState, action) => {
    switch (action.type) {
      case 'SET_SEARCH_MYCATALOGUE':
        return {
          ...state,
          search: action.payload.data
        };
        case 'SET_CATAGORY_MYCATALOGUE':
            return {
              ...state,
              category: action.payload.data
            };
      case 'SET_CART_COUNT':
        return {
          ...state,
          cartCount: action.payload.data
        };
        case 'SET_CATEGORY_LIST':
          return {
            ...state,
            categoryList: action.payload.data
          };
      case 'RESET_SEARCH_STATE':
        return {
          ...state,
          ...initState
        };
      default:
        return { ...state };
    }
  };
  
  export default SearchMyCatalogueReducer;
  