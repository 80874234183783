export const setPromotion = (value, page, limit, count) => {
    return {
      type: 'SET_PROMOTION',
      payload: { data: value, page, limit, count }
    };
  };

  export const setActivePromotion = (value) => {
    return {
      type: 'SET_ACTIVE_PROMOTION',
      payload: { data: value }
    };
  };