// import external modules
import React, { Suspense, lazy } from "react";
import ReactDOM from "react-dom";
import { Provider } from "react-redux";
import { updateAccountApi } from "./redux/actions/apiActions/apiAction";

// import internal(own) modules
import * as serviceWorker from "./serviceWorker";
import { store } from "./redux/storeConfig/store";
import ReduxToastr from "react-redux-toastr";
import history from "./app/history";
import Spinner from "./components/Spinner";

import "./index.scss";

const LazyApp = lazy(() => import("./app/AppRouter"));

const jsx = (
  <Provider store={store}>
    <Suspense fallback={<Spinner />}>
      <LazyApp />
      <ReduxToastr
        timeOut={4000}
        newestOnTop={false}
        preventDuplicates
        position="top-right"
        transitionIn="fadeIn"
        transitionOut="fadeOut"
        progressBar
        closeOnToastrClick
      />
    </Suspense>
  </Provider>
);
ReactDOM.render(jsx, document.getElementById("root"));

// let hasRendered = false;

const renderApp = (user = "") => {
  // if (!hasRendered) {
  // if(user)
  //call socket here
  ReactDOM.render(jsx, document.getElementById("root"));
  // registerServiceWorker();
  // hasRendered = true;
  // }
};

// ReactDOM.render(<Spinner />, document.getElementById("root"));
serviceWorker.register();

const renderLogin = () => {
  // store.dispatch(setIsAuthenticated(false));
  renderApp();
  // history.push('/');
};

/**
 *
 * @param {*} user
 * @todo - move this to redux
 */
// const handleToken = async (user) => {
//   const roleResponse = await api(`v1/organization/${user.organizationId}/user/${user.id}/role`, {}, "get");
//   if (roleResponse.status === 200) {
//     await subscribeToAircraft({ id: user.id });
//     store.dispatch(setPermission(roleResponse.data.organizationRole.web_access));
//     return renderApp(user);
//   }
//   store.dispatch(logout());
//   return renderLogin();
// };

try {
  let user = localStorage.getItem("user");

  if (user) {
    user = JSON.parse(user);
    store.dispatch({
      type: "LOGIN_WITH_JWT",
      payload: { loggedInUser: user, loggedInWith: "jwt" },
    });
    store.dispatch({
      type: "SET_LOGIN_FLAG",
      payload: { data: true },
    });
    switch (user.role) {
      case "admin":
        store.dispatch({
          type: "CHANGE_ROLE",
          payload: { data: "A" },
        });
        break;
      case "internal_sales":
        store.dispatch({
          type: "CHANGE_ROLE",
          payload: { data: "S" },
        });
        break;
      default:
        store.dispatch({
          type: "CHANGE_ROLE",
          payload: { data: "C" },
        });
        store.dispatch(updateAccountApi(user.account.id));
    }
    // store.dispatch({
    //   type: "CHANGE_ROLE",
    //   payload: {data: user.role}
    // })
    // history.push("history.location.pathname");
  } else {
    renderLogin();
  }
} catch (e) {
  renderLogin();
}
