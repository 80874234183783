const initState = {
  catalogueCategory: [],
};
const catalogueCategoryReducer = (state = initState, action) => {
  switch (action.type) {
    case 'SET_CATALOGUE_CATEGORY':
      return {
        ...state,
        catalogueCategory: action.payload.data,
      };
    case 'RESET_CATALOGUE_CATEGORY_STATE':
      return {
        ...state,
        ...initState,
      };
    default:
      return { ...state };
  }
};

export default catalogueCategoryReducer;
