
  
const initState = {
    myCatalogue: [],
    page: 1,
    limit: 20,
    count: 0,
    filter:{ category: [], industry: [], brand: [], promo: [] },
    sort:"createdAt DESC"
  };
  
  const MyCatalogueReducer = (state = initState, action) => {
    switch (action.type) {
      case 'SET_MYCATALOGUE':
        return {
          ...state,
          myCatalogue: action.payload.data,
          page: action.payload.page,
          limit: action.payload.limit,
          count: action.payload.count,
          filter : action.payload.filter,
          sort:  action.payload.sort,
        };
      case 'RESET_MYCATALOGUE':
        return {
          ...state,
          ...initState
        };
      default:
        return { ...state };
    }
  };
  
  export default MyCatalogueReducer;
  