// import external modules
import { combineReducers } from 'redux';
import { reducer as toastrReducer } from 'react-redux-toastr';
import authReducers from './auth/index';
import newsReducer from './news/newsReducer';
import promotionReducer from './promotion/promotionReducer';
import subscriberReducer from './subscriber/subscriberReducer';
import lasherCatalogueReducer from './lasherCatalogue/lasherCatalogueReducer';
import myCatalogueReducer from './myCatalogue/myCatalogueReducer';
import catalogueCategoryReducer from './catalogueCategory/catalogueCategoryReducer';
import catalogueItemReducer from './catalogueItem/catalogueItemReducer';
import marketingCategoryReducer from './marketingCategory/marketingCategoryReducer';
import trainingCategoryReducer from './trainingCategory/trainingCategoryReducer';
import marketingItemReducer from './marketingItem/marketingItemReducer';
import trainingItemReducer from './trainingItem/trainingItemReducer';
import auditTrailReducer from './auditTrail/auditTrailReducer';
import stockSheetReducer from './stockSheet/stockSheetReducer';
import relationReducer from './relation/relationReducer';
import companyReducer from './company/companyReducer';
import companyUsersReducer from './companyUsers/companyUsersReducer';
import customerCompanyUsersReducer from './customerCompanyUsers/customerCompanyUsersReducer';
import switchAccountReducer from './switchAccount/switchAccountReducer';
import repContactAccountReducer from './repContactAccount/repContactAccountReducer';
import openOrderReducer from './openOrder/openOrderReducer';
import backOrderReducer from './backOrder/backOrderReducer';
import invoiceReducer from './invoice/invoiceReducer';
import cartReducer from './cart/cartReducer';

// import repContactReducer from "./repContact/repContactReducer";

// import internal(own) modules

import LayoutReducer from './layoutReducer';
import RepContactReducer from './repContact/repContactReducer';
import AdminDashboardReducer from './adminDashboard/adminDashboardReducer';
import SearchMyCatalogueReducer from './searchMyCatalogue/searchMyCatalogueReducer';

const rootReducer = combineReducers({
  toastr: toastrReducer, // <- Mounted at toastr.
  layout: LayoutReducer,
  auth: authReducers,
  news: newsReducer,
  promotion: promotionReducer,
  subscriber: subscriberReducer,
  lasherCatalogue: lasherCatalogueReducer,
  myCatalogue: myCatalogueReducer,
  marketingCategory: marketingCategoryReducer,
  trainingCategory: trainingCategoryReducer,
  marketingItem: marketingItemReducer,
  trainingItem: trainingItemReducer,
  auditTrail: auditTrailReducer,
  stockSheet: stockSheetReducer,
  relation: relationReducer,
  company: companyReducer,
  companyUsers: companyUsersReducer,
  customerCompanyUsers: customerCompanyUsersReducer,
  switchAccount: switchAccountReducer,
  repContactAccount: repContactAccountReducer,
  repContact: RepContactReducer,
  openOrder: openOrderReducer,
  backOrder: backOrderReducer,
  invoice: invoiceReducer,
  cart: cartReducer,
  adminDashboard: AdminDashboardReducer,
  searchMyCatalogue: SearchMyCatalogueReducer,
  catalogueCategory: catalogueCategoryReducer,
  catalogueItem: catalogueItemReducer,
});

export default rootReducer;
