import { api } from '../../../api/api';
import { setNews } from '../news/newsAction';
import { setPromotion, setActivePromotion } from '../promotion/promotionAction';
import { setSubscriber } from '../subscriber/subscriberAction';
import { toastr } from 'react-redux-toastr';
import { setLasherCatalogue } from '../lasherCatalogue/lasherCatalogueAction';
import { setMarketingCategory } from '../marketingCategory/marketingCategoryAction';
import { setTrainingCategory } from '../trainingCategory/trainingCategoryAction';
import { setMarketingItem } from '../marketingItem/marketingItemAction';
import { setTrainingItem } from '../trainingItem/trainingItemAction';
import { setCatalogueItem } from '../catalogueItem/catalogueItemAction';
import { setCatalogueCategory } from '../catalogueCategory/catalogueCategoryAction';
import { setAuditTrail } from '../auditTrail/auditTrailAction';
import { setStockSheet } from '../stockSheet/stockSheetAction';
import { setRelation } from '../relation/relationAction';
import { setCompany } from '../company/companyAction';
import { setCompanyUsers } from '../companyUsers/companyUsersAction';
import { setRepContactAccount } from '../repContactAccounts/repContactAccount';
import { setRepContact } from '../repContact/repContact';
import { setSwitchAccount } from '../switchAccount/switchAccountAction';
import { setMyCatalogue } from '../myCatalogue/myCatalogueAction';
import { setOpenOrder } from '../openOrder/openOrderAction';
import { setBackOrder } from '../backOrder/backOrderAction';
import { setInvoice } from '../invoice/invoiceAction';
import { setCart } from '../cart/cartAction';
import { setAdminDashboard } from '../adminDashboard/adminDashboardAction';
import {
  setCartCount,
  setCategoryList,
} from '../searchMyCatalogue/searchMyCatalogueAction';

export const newsApi = (search = '', page = 1, limit = 10) => {
  return async (dispatch) => {
    const data = {
      search,
      page,
      limit,
    };

    let response = await api(
      `/news?limit=${data.limit}&page=${page}&search=${search}`,
      {},
      'get'
    );
    if (response && response.status === 200) {
      dispatch(
        setNews(response.data.data, page, limit, response.data.totalNews)
      );
    } else {
      dispatch(setNews([]));
    }
  };
};

export const promotionApi = (search = '', page = 1, limit = 10) => {
  return async (dispatch) => {
    const data = {
      search,
      page,
      limit,
    };
    let response = await api(
      `/promotion?limit=${data.limit}&page=${page}`,
      {},
      'get'
    );
    if (response && response.status === 200) {
      // let countResponse = await api('/admin/count',{},'get');
      // if(countResponse.status === 200){
      dispatch(
        setPromotion(response.data.data, page, limit, response.data.totalPromos)
      );
      // }
    } else {
      dispatch(setPromotion([]));
    }
  };
};

export const marketingItemApi = (
  search = '',
  page = 1,
  limit = 10,
  sort = 'createdAt DESC'
) => {
  return async (dispatch) => {
    const data = {
      page,
      limit,
    };
    let response = await api(
      `/items?type=marketing&limit=${data.limit}&page=${page}&search=${search}&sort=${sort}`,
      {},
      'get'
    );
    if (response && response.status === 200) {
      // let countResponse = await api('/admin/count',{},'get');
      // if(countResponse.status === 200){
      dispatch(
        setMarketingItem(
          response.data.data,
          page,
          limit,
          response.data.totalArticles
        )
      );
      // }
    } else {
      dispatch(setMarketingItem([]));
    }
  };
};

export const trainingItemApi = (
  search = '',
  page = 1,
  limit = 10,
  sort = 'createdAt DESC'
) => {
  return async (dispatch) => {
    const data = {
      page,
      limit,
    };
    let response = await api(
      `/items?type=training&limit=${data.limit}&page=${page}&search=${search}&sort=${sort}`,
      {},
      'get'
    );
    if (response && response.status === 200) {
      // let countResponse = await api('/admin/count',{},'get');
      // if(countResponse.status === 200){
      dispatch(
        setTrainingItem(
          response.data.data,
          page,
          limit,
          response.data.totalArticles
        )
      );
      // }
    } else {
      dispatch(setTrainingItem([]));
    }
  };
};

export const trainingItemByCategoryApi = (
  search = '',
  page = 1,
  limit = 10,
  categories = [],
  sort = 'createdAt DESC'
) => {
  return async (dispatch) => {
    const data = {
      page,
      limit,
    };
    let response = await api(
      `/itemsByCategory?type=training&limit=${data.limit}&page=${page}&search=${search}&sort=${sort}`,
      { categories },
      'post'
    );
    if (response && response.status === 200) {
      // let countResponse = await api('/admin/count',{},'get');
      // if(countResponse.status === 200){
      dispatch(
        setTrainingItem(
          response.data.data,
          page,
          limit,
          response.data.totalArticles
        )
      );
      // }
    } else {
      dispatch(setTrainingItem([]));
    }
  };
};

export const marketingItemByCategoryApi = (
  search = '',
  page = 1,
  limit = 10,
  categories = [],
  sort = 'createdAt DESC'
) => {
  return async (dispatch) => {
    const data = {
      page,
      limit,
    };
    let response = await api(
      `/itemsByCategory?type=marketing&limit=${data.limit}&page=${page}&search=${search}&sort=${sort}`,
      { categories },
      'post'
    );
    if (response && response.status === 200) {
      // let countResponse = await api('/admin/count',{},'get');
      // if(countResponse.status === 200){
      dispatch(
        setMarketingItem(
          response.data.data,
          page,
          limit,
          response.data.totalArticles
        )
      );
      // }
    } else {
      dispatch(setMarketingItem([]));
    }
  };
};

export const marketingCategoryApi = (page = 1, limit = 1999) => {
  return async (dispatch) => {
    let response = await api(
      `/category?type=marketing&page=${page}&limit=${limit}`,
      {},
      'get'
    );
    if (response && response.status === 200) {
      // let countResponse = await api('/admin/count',{},'get');
      // if(countResponse.status === 200){
      dispatch(setMarketingCategory(response.data.data));
      // }
    } else {
      dispatch(setMarketingCategory([]));
    }
  };
};

export const trainingCategoryApi = (page = 1, limit = 1999) => {
  return async (dispatch) => {
    let response = await api(
      `/category?type=training&page=${page}&limit=${limit}`,
      {},
      'get'
    );
    if (response && response.status === 200) {
      // let countResponse = await api('/admin/count',{},'get');
      // if(countResponse.status === 200){
      dispatch(setTrainingCategory(response.data.data));
      // }
    } else {
      dispatch(setTrainingCategory([]));
    }
  };
};
export const catalogueItemApi = (
  search = '',
  page = 1,
  limit = 10,
  sort = 'createdAt DESC'
) => {
  return async (dispatch) => {
    const data = {
      page,
      limit,
    };
    let response = await api(
      `/items?type=pdf&limit=${data.limit}&page=${page}&search=${search}&sort=${sort}`,
      {},
      'get'
    );
    console.log(response, '---response---');
    if (response && response.status === 200) {
      console.log(response, '---response---');
      // let countResponse = await api('/admin/count',{},'get');
      // if(countResponse.status === 200){
      dispatch(
        setCatalogueItem(
          response.data.data,
          page,
          limit,
          response.data.totalArticles
        )
      );
      // }
    } else {
      dispatch(setCatalogueItem([]));
    }
  };
};
export const catalogueItemByCategoryApi = (
  search = '',
  page = 1,
  limit = 10,
  categories = [],
  sort = 'createdAt DESC'
) => {
  return async (dispatch) => {
    const data = {
      page,
      limit,
    };
    let response = await api(
      `/itemsByCategory?type=pdf&limit=${data.limit}&page=${page}&search=${search}&sort=${sort}`,
      { categories },
      'post'
    );
    if (response && response.status === 200) {
      // let countResponse = await api('/admin/count',{},'get');
      // if(countResponse.status === 200){
      dispatch(
        setCatalogueItem(
          response.data.data,
          page,
          limit,
          response.data.totalArticles
        )
      );
      // }
    } else {
      dispatch(setCatalogueItem([]));
    }
  };
};
export const catalogueCategoryApi = () => {
  return async (dispatch) => {
    let response = await api(`/category?type=pdf`, {}, 'get');
    if (response && response.status === 200) {
      // let countResponse = await api('/admin/count',{},'get');
      // if(countResponse.status === 200){
      dispatch(setCatalogueCategory(response.data.data));
      // }
    } else {
      dispatch(setCatalogueCategory([]));
    }
  };
};
export const activePromotionApi = () => {
  return async (dispatch) => {
    let response = await api(`/activePromotion`, {}, 'get');
    if (response && response.status === 200) {
      // let countResponse = await api('/admin/count',{},'get');
      // if(countResponse.status === 200){
      dispatch(setActivePromotion(response.data.data[0]));
      // }
    } else {
      dispatch(setActivePromotion({}));
    }
  };
};

export const subscriberApi = (search = '', page = 1, limit = 10) => {
  return async (dispatch) => {
    const data = {
      search,
      page,
      limit,
    };
    let response = await api(
      `/subscriber?limit=${data.limit}&page=${page}`,
      {},
      'get'
    );
    if (response && response.status === 200) {
      // let countResponse = await api('/admin/count',{},'get');
      // if(countResponse.status === 200){
      dispatch(
        setSubscriber(
          response.data.data,
          page,
          limit,
          response.data.totalSubscribers
        )
      );
      // }
    } else {
      dispatch(setSubscriber([]));
    }
  };
};

export const lasherCatalogueApi = (
  search = '',
  page = 1,
  limit = 10,
  noImages = true,
  sort = 'createdAt,-1',
  filter = { category: [], industry: [], brand: [] }
) => {
  return async (dispatch) => {
    const data = {
      search,
      page,
      limit,
      noImages,
    };
    console.log(data, 'data');
    let response = await api(
      `/products?limit=${data.limit}&page=${page}&image=${
        data.noImages
      }&search=${search}&field=${sort.split(',')[0]}&direction=${
        sort.split(',')[1]
      }`,
      { filter },
      'post'
    );
    if (response && response.status === 200) {
      // let countResponse = await api('/admin/count',{},'get');
      // if(countResponse.status === 200){
      dispatch(
        setLasherCatalogue(
          response.data.data,
          page,
          limit,
          response.data.totalProducts
        )
      );
      // }
    } else {
      dispatch(setLasherCatalogue([]));
    }
  };
};

export const myCatalogueApi = (
  id,
  search = '',
  page = 1,
  limit = 10,
  sort = 'createdAt DESC',
  filter = { category: [], industry: [], brand: [], promo: [] }
) => {
  return async (dispatch) => {
    const data = {
      search,
      page,
      limit,
    };
    let response = await api(
      `/productsWithPricing/${id}?limit=${data.limit}&page=${page}&search=${search}&sort=${sort}`,
      { filter },
      'post'
    );
    if (response && response.status === 200) {
      // console.log(response, 'response');
      // let countResponse = await api('/admin/count',{},'get');
      // if(countResponse.status === 200){
      dispatch(
        setMyCatalogue(
          response.data.data,
          page,
          limit,
          response.data.totalProducts,
          filter,
          sort
        )
      );
      // }
    } else {
      dispatch(setMyCatalogue([]));
    }
  };
};

export const auditTrailApi = (search = '', page = 1, limit = 10) => {
  return async (dispatch) => {
    const data = {
      search,
      page,
      limit,
    };
    let response = await api(
      `/audit?limit=${data.limit}&page=${page}`,
      {},
      'get'
    );
    if (response && response.status === 200) {
      // let countResponse = await api('/admin/count',{},'get');
      // if(countResponse.status === 200){
      dispatch(
        setAuditTrail(
          response.data.data,
          page,
          limit,
          response.data.totalAudits
        )
      );
      // }
    } else {
      dispatch(setAuditTrail([]));
    }
  };
};

export const StockSheetApi = (id, search = '', page = 1, limit = 10) => {
  return async (dispatch) => {
    const data = {
      search,
      page,
      limit,
    };
    let response = await api(
      `/stocksheetsByAccount/${id}?limit=${data.limit}&page=${page}&search=${search}`,
      {},
      'get'
    );
    if (response && response.status === 200) {
      // let countResponse = await api('/admin/count',{},'get');
      // if(countResponse.status === 200){
      dispatch(
        setStockSheet(
          response.data.data,
          page,
          limit,
          response.data.totalStockSheets
        )
      );
      // }
    } else {
      dispatch(setStockSheet([]));
    }
  };
};

export const RelationApi = (search = '', page = 1, limit = 10) => {
  return async (dispatch) => {
    const data = {
      search,
      page,
      limit,
    };
    let response = await api(
      `/relatedProducts?limit=${data.limit}&page=${page}&search=${search}`,
      {},
      'get'
    );
    if (response && response.status === 200) {
      dispatch(
        setRelation(
          response.data.data,
          page,
          limit,
          response.data.totalProducts
        )
      );
      // }
    } else {
      dispatch(setRelation([]));
    }
  };
};

export const CompanyUsersApi = (search = '', page = 1, limit = 10) => {
  return async (dispatch) => {
    const data = {
      search,
      page,
      limit,
    };
    let response = await api(
      `/user?limit=${data.limit}&page=${page}&search=${search}`,
      {},
      'get'
    );
    if (response && response.status === 200) {
      dispatch(
        setCompanyUsers(
          response.data.data,
          page,
          limit,
          response.data.totalUsers
        )
      );
      // }
    } else {
      dispatch(setCompanyUsers([]));
    }
  };
};

export const CustomerCompanyUsersApi = (
  id,
  search = '',
  page = 1,
  limit = 10
) => {
  return async (dispatch) => {
    const data = {
      search,
      page,
      limit,
    };
    let response = await api(
      `/userByAccount/${id}?limit=${data.limit}&page=${page}&search=${search}`,
      {},
      'get'
    );
    if (response && response.status === 200) {
      if (response.data.data.length > 0)
        dispatch(
          setCompanyUsers(
            response.data.data,
            page,
            limit,
            response.data.totalUsers
          )
        );
      else dispatch(setCompanyUsers([]));
      // }
    } else {
      dispatch(setCompanyUsers([]));
    }
  };
};

export const CompanyApi = () => {
  return async (dispatch) => {
    // console.log('Here');
    let response = await api(`/accounts`, {}, 'get');
    if (response && response.status === 200) {
      dispatch(setCompany(response.data.data));
      // }
    } else {
      dispatch(setCompany([]));
    }
  };
};

export const RepContactAccount = (id, search = '', page = 1, limit = 10) => {
  return async (dispatch) => {
    const data = {
      search,
      page,
      limit,
    };
    let response = await api(
      `/accountsForRep/${id}?limit=${data.limit}&page=${page}&search=${search}`,
      {},
      'get'
    );
    if (response && response.status === 200) {
      if (response.data.data.length > 0)
        dispatch(
          setRepContactAccount(
            response.data.data,
            page,
            limit,
            response.data.totalAccounts
          )
        );
      else dispatch(setRepContactAccount([]));
      // }
    } else {
      dispatch(setRepContactAccount([]));
    }
  };
};

export const RepContact = (id, search = '', page = 1, limit = 10) => {
  return async (dispatch) => {
    const data = {
      search,
      page,
      limit,
    };
    let response = await api(
      `/repContactsByAccount/${id}?limit=${data.limit}&page=${page}&search=${search}`,
      {},
      'get'
    );
    if (response && response.status === 200) {
      if (response.data.data.length > 0)
        dispatch(
          setRepContact(
            response.data.data,
            page,
            limit,
            response.data.totalRepContacts
          )
        );
      else dispatch(setRepContact([]));
      // }
    } else {
      dispatch(setRepContact([]));
    }
  };
};

export const SwitchAccountApi = (
  userId,
  accountId,
  search = '',
  page = 1,
  limit = 10
) => {
  return async (dispatch) => {
    const data = {
      search,
      page,
      limit,
    };
    let response = await api(
      `/accountByUser/${userId}/${accountId}?limit=${data.limit}&page=${page}&search=${search}`,
      {},
      'get'
    );
    if (response && response.status === 200) {
      if (response.data.data.length > 0)
        dispatch(
          setSwitchAccount(
            response.data.data,
            page,
            limit,
            response.data.totalAccounts
          )
        );
      else dispatch(setSwitchAccount([]));
      // }
    } else {
      dispatch(setSwitchAccount([]));
    }
  };
};

export const AdminSwitchAccountApi = (search = '', page = 1, limit = 10) => {
  return async (dispatch) => {
    const data = {
      search,
      page,
      limit,
    };
    let response = await api(
      `/accounts?limit=${data.limit}&page=${page}&searchKey=${search}`,
      {},
      'get'
    );
    if (response && response.status === 200) {
      if (response.data.data.length > 0)
        dispatch(
          setSwitchAccount(
            response.data.data,
            page,
            limit,
            response.data.totalAccounts
          )
        );
      else dispatch(setSwitchAccount([]));
      // }
    } else {
      dispatch(setSwitchAccount([]));
    }
  };
};

export const BackOrderApi = (id, search = '', page = 1, limit = 10) => {
  return async (dispatch) => {
    const data = {
      search,
      page,
      limit,
    };

    let response = await api(
      `/uniqueOrders/${id}?backOrder=true&limit=${data.limit}&page=${page}&search=${search}`,
      {},
      'get'
    );
    if (response && response.status === 200) {
      dispatch(
        setBackOrder(response.data.data, page, limit, response.data.totalOrders)
      );
    } else {
      dispatch(setBackOrder([]));
    }
  };
};
export const OneOrderApi = (account, order) => {
  return async (dispatch) => {
    let response = await api(`/uniqueOrderById/${account}/${order}`, {}, 'get');
    if (response && response.status === 200) {
      return response;
    } else {
      toastr.error('Something went wrong while fetching Order details');
    }
  };
};

export const OpenOrderApi = (id, search = '', page = 1, limit = 10) => {
  return async (dispatch) => {
    const data = {
      search,
      page,
      limit,
    };

    let response = await api(
      `/uniqueOrders/${id}?backOrder=false&limit=${data.limit}&page=${page}&search=${search}`,
      {},
      'get'
    );
    if (response && response.status === 200) {
      dispatch(
        setOpenOrder(response.data.data, page, limit, response.data.totalOrders)
      );
    } else {
      dispatch(setOpenOrder([]));
    }
  };
};
export const OrdersApi = (search = '', page = 1, type = 'all') => {
  return async (dispatch) => {
    const data = {
      search,
      page,
    };

    let response = await api(
      `/orders?backOrder=false&limit=${20}&page=${page}&search=${search}&type=${type}`,
      {},
      'get'
    );
    if (response && response.status === 200) {
      dispatch(
        setOpenOrder(response.data.data, page, 20, response.data.totalOrders)
      );
    } else {
      dispatch(setOpenOrder([]));
    }
  };
};

export const InvoiceApi = (
  id,
  search = '',
  page = 1,
  limit = 10,
  start = 0,
  end = 0
) => {
  return async (dispatch) => {
    const data = {
      search,
      page,
      limit,
    };

    let response = await api(
      `/uniqueInvoice/${id}?limit=${data.limit}&page=${page}&search=${search}`,
      { start, end },
      'post'
    );
    // console.log(response, 'response api');
    if (response && response.status === 200) {
      dispatch(
        setInvoice(response.data.data, page, limit, response.data.totalInvoices)
      );
    } else {
      dispatch(setInvoice([]));
    }
  };
};
export const OneInvoiceApi = (account, invoice) => {
  return async (dispatch) => {
    let response = await api(
      `/uniqueInvoiceById/${account}/${invoice}`,
      {},
      'get'
    );
    // console.log(response, 'response api');
    if (response && response.status === 200) {
      return response;
    } else {
      toastr.error('Something went wrong while fetching Invoice details');
    }
  };
};
export const CartApi = (id) => {
  return async (dispatch) => {
    let response = await api(`/carts/${id}`, {}, 'get');
    if (response && response.status === 200) {
      if (response.data.data) {
        dispatch(setCart(response.data.data));
        dispatch(
          setCartCount(
            response.data.data.productsWithQty
              ? response.data.data.productsWithQty.length
              : 0
          )
        );
      } else {
        dispatch(setCart([]));
        dispatch(setCartCount(0));
      }
    } else {
      dispatch(setCart([]));
    }
  };
};

export const AdminDashboardApi = () => {
  return async (dispatch) => {
    // console.log('Here');
    let response = await api(`/dashboard`, {}, 'get');
    if (response && response.status === 200) {
      dispatch(setAdminDashboard(response.data.data));
      // }
    } else {
      dispatch(setAdminDashboard({}));
    }
  };
};

export const CategoryListApi = () => {
  return async (dispatch) => {
    // console.log('Here');
    let response = await api(`/productsCategories`, {}, 'get');
    if (response && response.status === 200) {
      let array = [];
      response.data.data.map((one) => {
        array.push({ id: one.id, title: one.title });
      });

      dispatch(setCategoryList(array));
    } else {
      dispatch(setCategoryList([]));
    }
  };
};

// export const transactionApi = (search = "", page = 1, limit = 10) => {
//   return async dispatch => {
//     const data = {
//       search,
//       page,
//       limit
//     };
//     let skip=(page-1)*limit;
//     // const artistResult = await api("admin/get/artist", data, "post");
//     let response={};
//     if(skip > 0 ){
//       response = await api(`/payment/receipts?limit=${data.limit}&skip=${skip}`,{}, 'get');
//     }
//     else{
//       response = await api(`/payment/receipts?limit=${data.limit}`,{}, 'get');
//     }
//     if (response && response.status === 200) {
//       // let countResponse = await api('/admin/count',{},'get');
//       // if(countResponse.status === 200){
//         // console.log(response.data.count);
//         dispatch(
//           setTransactions(
//               response.data.data,
//             page,
//             limit,
//             response.data.count
//           )
//         );
//       // }
//     } else {
//       dispatch(setTransactions([]));
//     }
//   };
// };

// export const serviceProviderApi = (search = "", page = 1, limit = 10) => {
//   return async dispatch => {
//     console.log("page",page);
//     const data = {
//       search,
//       page,
//       limit
//     };
//     let skip=(page-1)*limit;
//     // const artistResult = await api("admin/get/artist", data, "post");
//     let response={};
//     if(skip > 0 ){
//       response = await api(`/auth/firebase/admin/user?role=service-provider&limit=${data.limit}&skip=${skip}`,{}, 'get');
//     }
//     else{
//       response = await api(`/auth/firebase/admin/user?role=service-provider&limit=${data.limit}`,{}, 'get');
//     }
//     if (response && response.status === 200) {
//         dispatch(
//           setServiceProviders(
//               response.data.data.user,
//             page,
//             limit,
//             response.data.data.count
//           )
//         );
//     } else {
//       dispatch(setServiceProviders([]));
//     }
//   };
// };

// export const pendingEnquiryApi = (search = "", page = 1, limit = 10) => {
//   return async dispatch => {
//     console.log("page",page);
//     const data = {
//       search,
//       page,
//       limit
//     };
//     let skip=(page-1)*limit;
//     // const artistResult = await api("admin/get/artist", data, "post");
//     let response={};
//     if(skip > 0 ){
//       response = await api(`/auth/firebase/admin/status/user?verificationStatus=pending&limit=${data.limit}&skip=${skip}`,{}, 'get');
//     }
//     else{
//       response = await api(`/auth/firebase/admin/status/user?verificationStatus=pending&limit=${data.limit}`,{}, 'get');
//     }
//     if (response && response.status === 200) {
//         dispatch(
//           setPendingEnquiries(
//               response.data.data.user,
//             page,
//             limit,
//             response.data.data.count
//           )
//         );
//     } else {
//       dispatch(setPendingEnquiries([]));
//     }
//   };
// };

// export const categoryApi = () => {
//   return async dispatch => {

//     let  response = await api(`/order/category`,{}, 'get');
//     if (response && response.status === 200) {
//         dispatch(
//           setCategories(
//               response.data.data
//           )
//         );
//     } else {
//       dispatch(setCategories([]));
//     }
//   };
// };

export const updateNews = async (id, data) =>
  await api(`/news/${id}`, data, 'patch');

export const addNews = async (data) => {
  try {
    let response = await api(`/news`, data, 'post');
    return response;
  } catch (error) {
    toastr.error('Something Went Wrong. Try Again');
  }
};

export const deleteNews = async (id) => await api(`/news/${id}`, {}, 'delete');

export const updatePromotion = async (id, data) =>
  await api(`/promotion/${id}`, data, 'patch');

export const updatePromotionStatus = async (id, data) =>
  await api(`/promotion/${id}`, data, 'patch');

export const addPromotion = async (data) => {
  try {
    let response = await api(`/promotion`, data, 'post');
    return response;
  } catch (error) {
    toastr.error('Something Went Wrong. Try Again');
  }
};

export const deletePromotion = async (id) =>
  await api(`/promotion/${id}`, {}, 'delete');

export const updateSubscriber = async (id, data) =>
  await api(`/subscriber/${id}`, data, 'patch');

export const addSubscriber = async (data) => {
  try {
    let response = await api(`/subscriber`, data, 'post');
    return response;
  } catch (error) {
    toastr.error('Something Went Wrong. Try Again');
  }
};

export const deleteSubscriber = async (id) =>
  await api(`/subscriber/${id}`, {}, 'delete');

export const createCategory = async (data) =>
  await api(`/category`, data, 'post');

export const updateCategory = async (id, data) =>
  await api(`/category/${id}`, data, 'patch');

export const deleteCategory = async (id) =>
  await api(`/category/${id}`, {}, 'delete');

export const deleteOldCategory = async (categoryId, subCategoriesId) =>
  await api(`/categoryUnchecked`, { categoryId, subCategoriesId }, 'delete');

export const deleteItem = async (id) => await api(`/items/${id}`, {}, 'delete');
export const createItem = async (data) => await api(`/items`, data, 'post');
export const updateItem = async (id, data) =>
  await api(`/items/${id}`, data, 'patch');

export const updateProductImage = async (id, image) =>
  await api(`/products/${id}`, { image: image }, 'patch');

export const customerRegistration = async (data) =>
  await api(`/customerAccount`, data, 'post');

export const mailRegisteredCustomer = async (data) =>
  await api(`/sendCustomerRegistrationForm`, { id: data }, 'post');

export const SubmitStandForm = async (data) =>
  await api(`/sendEventStandUpForm`, data, 'post');

export const SubmitStandAgreementForm = async (data) =>
  await api(`/sendStandAgreementForm`, data, 'post');

export const createStockSheet = async (data) =>
  await api(`/stocksheets`, data, 'post');

export const deleteStockSheet = async (id) =>
  await api(`/stocksheets/${id}`, {}, 'delete');

export const updateStockSheet = async (data, id) =>
  await api(`/stocksheets/${id}`, data, 'patch');

export const getAllProducts = async () => await api(`/allproducts`, {}, 'get');

export const createRelation = async (data) =>
  await api(`/relatedProducts`, data, 'post');

export const updateRelation = async (data, id) =>
  await api(`/relatedProducts/${id}`, data, 'patch');

export const deleteRelation = async (id) =>
  await api(`/relatedProducts/${id}`, {}, 'delete');

export const createUser = async (data) => await api(`/user`, data, 'post');

export const updateUser = async (id, data) =>
  await api(`/user/${id}`, data, 'patch');

export const deleteUser = async (id, account) =>
  await api(`/user/${id}`, { account: account }, 'delete');

export const createRepContact = async (data) =>
  await api(`/createRepContacts`, data, 'post');

export const updateCart = async (id, data) => {
  let response = await api(`/carts/${id}`, data, 'patch');
  // console.log(response, 'updatedCart');
  return response;
};

export const postOrder = async (data) => {
  // console.log(data);
  let response = await api(`/orderProcess`, data, 'post');
  return response;
};
export const postOrderCreate = async (data) => {
  // console.log(data);
  let response = await api(`/orderProcessCreate`, data, 'post');
  return response;
};
export const sendProductData = async (id, data) => {
  let response = await api(
    `/sendProductDetail`,
    { account: id, details: data },
    'post'
  );
  return response;
};

export const resendPassword = async (email) => {
  let response = await api(`/resendPassword`, { email: email }, 'post');
  return response;
};
export const changePassword = async (id, oldpassword, newpassword) =>
  await api(`/changePassword/${id}`, { oldpassword, newpassword }, 'patch');

export const getProductIdCart = async (account, code) => {
  let response = await api(
    `/productsWithPricing/${account}/product/${code}`,
    {},
    'get'
  );
  return response;
};

export const stocksheetToCart = async (account, id) => {
  let response = await api(`/stocksheets/${id}/${account}`, {}, 'get');
  // console.log(response, 'stocksheetToCart');
  return response;
};

export const allRepContact = (
  search = '',
  page = 1,
  limit = 10,
  start = 0,
  end = 0
) => {
  let date = new Date(start).getTime();
  // console.log(date);
  return async (dispatch) => {
    const data = {
      search,
      page,
      limit,
    };
    let response = await api(
      `/repContacts?limit=${data.limit}&page=${page}&search=${search}`,
      { start, end },
      'post'
    );
    // console.log(response);
    if (response && response.status === 200) {
      if (response.data.data.length > 0)
        dispatch(
          setRepContact(
            response.data.data,
            page,
            limit,
            response.data.totalRepContacts
          )
        );
      else dispatch(setRepContact([]));
      // }
    } else {
      dispatch(setRepContact([]));
    }
  };
};

export const updateAccountApi = (id) => {
  return async (dispatch) => {
    let response = await api(`/accountFromSyspro/${id}`, {}, 'patch');
    // if(respon)
  };
};

export const printStocksheet = (id, accountId) => {
  return async (dispatch) => {
    let response = await api(`/printStocksheet/${id}/${accountId}`, {}, 'get');
    return response;
  };
};
export const printCart = (id) => {
  return async (dispatch) => {
    let response = await api(`/printCart/${id}`, {}, 'get');
    return response;
  };
};
export const printProducts = (id, products) => {
  return async (dispatch) => {
    let response = await api(
      `/productsPrint/${id}`,
      { products: products },
      'post'
    );
    // console.log(response);
    return response;
  };
};
export const addInvoice = (id, invoiceId) => {
  return async (dispatch) => {
    let response = await api(`/addPrevInvoice/${id}/${invoiceId}`, {}, 'patch');
    if (response.status === 200) {
      toastr.success('Added to cart successfully');
    } else {
      toastr.error('Something Went Wrong. Try Again');
    }
  };
};
export const updateMenuOrderApi = (data) => {
  return async (dispatch) => {
    let response = await api(`/category/order`, data, 'patch');
    if (response && response.status === 200) {
      toastr.success('Category Order Updated Successfully');
    } else {
      toastr.error('Menu Order Not Updated');
    }
  };
};
export const uploadFile = async (data) => {
  // return async (dispatch) => {
  console.log(data, 'data');
  const formData = new FormData();

  // Update the formData object
  formData.append('oldFile', data.oldFile);
  formData.append('file', data.file, data.file.name);

  let response = await api(`/upload-file/${data.fileType}`, formData, 'post');
  // console.log(response);
  return response;
  // };
};
export const clearCart = async (id) => {
  let response = await api(`/carts/clear/${id}`, {}, 'patch');
  if (response && response.status === 200) {
    toastr.success('Cart cleared successfully');
  } else {
    toastr.error('Something went wrong');
  }
};

export const myOrderedCatalogueApi = (
  customer,
  search = '',
  page = 1,
  limit = 10,
  sort = 'InvoiceDate,-1',
  filter = { startDate: '', endDate: '' }
) => {
  return async (dispatch) => {
    const data = {
      search,
      page,
      limit,
    };
    let response = await api(
      `/orderedProductsWithPricing/${customer}?limit=${
        data.limit
      }&page=${page}&search=${search}&field=${sort.split(',')[0]}&direction=${
        sort.split(',')[1]
      }`,
      { filter },
      'post'
    );
    if (response && response.status === 200) {
      return { items: response.data.data, total: response.data.totalCount };
    } else {
      toastr.error('Something went wrong. Unable to fetch data.');
      return { items: [], total: 0 };
    }
  };
};
